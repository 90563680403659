import React from "react";
import css from "./footer.module.css"
import Logo from "../../assets/logo.png"
import{
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon,
} from "@heroicons/react/outline";

const Footer=()=>{
    return(
        <dic className={css.cFooterWrapper}>
            <hr/>

            <div className={css.cFooter}>
                <div className={css.cLogo}>
                    <img src={Logo} alt="" />
                    <span>Shamik's Catalogue</span>
                </div>

                <div className={css.block}>
                    <div className={css.details}>
                        <span>Contact Us</span>
                        <span className={css.pngLine}>
                            <LocationMarkerIcon className={css.icon} />
                            <span>Bangalore, India, 560078</span>
                        </span>

                        
                        <span className={css.pngLine}>
                            <PhoneIcon className={css.icon} />
                            <span>=91 8451867479</span>
                        </span>

                        
                        <span className={css.pngLine}>
                            <InboxIcon className={css.icon} />
                            <span>shamik.miscrits@gmail.com</span>
                        </span>
                    </div>
                </div>

                <div className={css.block}>
                    <div className={css.details}>
                        <span>Account</span>
                        <span className={css.pngLine}>
                            <LoginIcon className={css.icon} />
                            <span>Sign In</span>
                        </span>
                    </div>
                </div>

                <div className={css.block}>
                    <div className={css.details}>
                        <span>Company</span>
                        <span className={css.pngLine}>
                            <UsersIcon className={css.icon} />
                            <span>About Us</span>
                        </span>
                    </div>
                </div>

                <div className={css.block}>
                    <div className={css.details}>
                        <span>Resources</span>
                        <span className={css.pngLine}>
                            <LinkIcon className={css.icon} />
                            <span>Safety Privacy and Terms</span>
                        </span>
                    </div>
                </div>

                
            </div>
            <div className={css.copyRight}>
                    <span>Copyright 2022 by Shamik, Inc</span>
                    <span>All Rights Reserved</span>
                </div>
        </dic>
    )
}

export default Footer