import Virtual  from "./components/Virtual/virtual";
import "./App.css";
import Header from "./components/header/Header"
import Hero from "./components/Hero/Hero"
import Slider from "./components/slider/slider";
import Product from "./components/Products/products";
import Testimonial from "./components/Testimonials/testimonials";
import Footer from "./components/Footer/footer";
function App() {
  return (
    <div className="App">
        <Header/>
        <Hero/>
        <Slider/>
        <Virtual/>
        <Product/>
        <Testimonial/>
        <Footer/>
    </div>
  );
}

export default App;
